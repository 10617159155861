import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactGA from 'react-ga4';
import OneSignal from 'react-onesignal';
import config from './config';
import projectInfo from '../package.json';

export const initAppServer = () => {
	Bugsnag.start({
		apiKey: config.bugsnagApiKey,
		appVersion: projectInfo.version,
		plugins: [new BugsnagPluginReact()],
	});
};

export const initAppClient = () => {
	ReactGA.initialize(config.gaMeasurementId);
	OneSignal.init({
		appId: config.onesignalAppId,
		serviceWorkerParam: { scope: '/public/onesignal/' },
		serviceWorkerPath: '/public/onesignal/OneSignalSDKWorker.js',
	});
};
